<template>
  <div>请稍等...</div>
</template>

<script>

export default {
  name: 'SpecificLogin',
  created() {
    this.$store.commit('verifyUserWithoutLogin', { that: this, subname: this.$router.currentRoute.query.subname })
  }
}
</script>

<style scoped>

</style>
